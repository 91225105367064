<template>
  <div class="">
    <Form ref="formRef" class="fu-form-mini-theme fu-form-mini">
      <field
        v-model="form.policyNo"
        :rules="[{ required: true, message: $t('claim.policyNo') }]"
        required
        :name="'policyNo'"
        autosize
        :label="$t('claim.PolicyNoLabel')"
      />
    </Form>
  </div>

</template>

<script>
import { Form, Field } from 'vant'
import Mixin from '@/views/claim/report/mixin.js'
import { Session } from '@/utils/storage'

export default {
  components: { Form, Field },
  mixins: [Mixin],
  props: {},
  data() {
    return {
      form: {
        policyNo: ''
      }
    }
  },
  inject: ['reshow'],
  mounted() {
    if (this.reshow()) {
      const res = Session.get('reportby')
      this.form = {
        ...res
      }
    }
  },
  methods: {
  }
}
</script>
<style lang="scss">
@import '@/components/FuFormMin/styles/index.scss';
@import '@/components/FuFormMin/styles/theme.scss';
</style>
<style lang="scss" scoped>
.fu-form-mini {
  ::v-deep .van-cell {
    padding: 0;
    overflow: visible;
    display: unset;
  }
  ::v-deep .van-field__label {
    width: unset;
  }
  ::v-deep .van-field__value {
    margin: 0px 2px 2px;
  }
  ::v-deep .van-field__body {
    flex-direction: row-reverse;
  }
}
::v-deep  .van-picker-column__item .van-notice-bar__wrap{
  justify-content: center;
}

.fu-min-select-box {
  // min-height: 30vh;
  min-height: 20vh;
  max-height: 60vh;
}

.select-tit {
  font-size: 12px;
  font-weight: 900;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.fu-select-list-cell.active {
  color: #ee0a24;
  font-weight: 500;
}
</style>
