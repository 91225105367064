<template>
  <div class="product-category-list">
    <p class="category-title">{{ $t('claim.ChooseYourClaimProduct') }}</p>
    <div class="category-flex">
      <div
        v-for="(item, index) in categoryList"
        :key="index"
        :class="value == item.categoryCode ? 'category-flex-item category-flex-item-active' : 'category-flex-item category-flex-item-normal'"
        @click="handleClick(item)"
      >
        <VanImage :src="getImg(item.categoryCode)" class="category-flex-item-img" />
        <p class="category-flex-item-text">{{ $t(`claim.${item.categoryName}`) }}</p>
      </div>
    </div>

    <Footer :disabled="!value" @next="handleNext" />
  </div>

</template>

<script>
import { Image as VanImage } from 'vant'
import Footer from '../footer/index.vue'
import { claimCategory } from '@/views/claim/api/index.js'

import carActiveImg from '@/assets/imgs/claim-car-active.png'
import motoActiveImg from '@/assets/imgs/claim-moto-active.png'
import travelActiveImg from '@/assets/imgs/claim-travel-active.png'
import propertyActiveImg from '@/assets/imgs/claim-property-active.png'
import carInActiveImg from '@/assets/imgs/claim-car-inactive.png'
import motoInActiveImg from '@/assets/imgs/claim-moto-inactive.png'
import travelInActiveImg from '@/assets/imgs/claim-travel-inactive.png'
import propertyInActiveImg from '@/assets/imgs/claim-property-inactive.png'
import Dao from '@/utils/dao'

export default {
  components: { VanImage, Footer },
  props: {
    value: { type: String, default() { return '' } }
  },
  data() {
    return {
      carActiveImg,
      motoActiveImg,
      travelActiveImg,
      propertyActiveImg,
      carInActiveImg,
      motoInActiveImg,
      travelInActiveImg,
      propertyInActiveImg,
      step: 1,
      categoryList: []
    }
  },
  computed: {
    getImg() {
      return (category) => {
        // return this[`img${index}`]
        if (this.value != category) return this[`${category}InActiveImg`]
        return this[`${category}ActiveImg`]
      }
    }
  },
  mounted() {
    this.preloadImages()
    this.interval = setInterval(() => {
      if (Dao.get('claimToken')) {
        this.claimCategory()
        clearInterval(this.interval)
        this.interval = null
      }
    }, 1000)
  },
  methods: {
    preloadImages() {
      const images = [
        this.carActiveImg,
        this.motoActiveImg,
        this.travelActiveImg,
        this.propertyActiveImg,
        this.carInActiveImg,
        this.motoInActiveImg,
        this.travelInActiveImg,
        this.propertyInActiveImg
      ]

      images.forEach(src => {
        const img = new Image()
        img.src = src
      })
    },
    async claimCategory() {
      const res = await claimCategory({})
      this.categoryList = res
    },
    handleClick(e) {
      this.$emit('input', e.categoryCode)
    },
    handleNext() {
      this.$parent.step = 1
      // history.pushState(null, null, `${location.origin}/#/createReport?step=1`)
    }
  }
}
</script>
<style lang="scss" scoped>
.product-category-list {
  font-size: 14px;
}
.category-title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}
.category-flex {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5%;
  .category-flex-item {
    padding: 15px 10px;
    flex-basis: 30%;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 10px;
  }
  .category-flex-item-normal {
    border: 1px solid #EBEDF0;
  }
  .category-flex-item-active {
    background-color: #fff6e8;
    border: 1px solid #E28800;
  }
  .category-flex-item-img {
    // width: 70%;
    height: 60px;
    max-width: 104px;
  }
  .category-flex-item-text {
    margin: 10px 0 0;
    font-weight: bold;
  }
}
</style>
