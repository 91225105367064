<template>
  <div class="">
    <Form ref="formRef" class="fu-form-mini-theme fu-form-mini">
      <field
        v-if="isIDEnv"
        :rules="[{ required: true, message: $t('claim.travelRulesBirthday') }]"
        required
        readonly
        clickable
        :value="form.dateText"
        :name="'plateCode'"
        right-icon="arrow-down"
        autosize
        :label="$t('claim.BirthdayLabel')"
        @click="visibleForPopup = true"
      />

      <field
        v-model="form.firstName"
        class="form-item-input"
        :rules="[{ required: true, message: $t('claim.travelRulesFirstName') }]"
        required
        :name="'firstName'"
        autosize
        :label="$t('claim.FirstNameLabel')"
      />

      <field
        v-model="form.lastName"
        :rules="[{ required: true, message: $t('claim.travelRulesLastName') }]"
        required
        :name="'lastName'"
        autosize
        :label="$t('claim.LastNameLabel')"
      />

      <field
        v-model="form.identityNo"
        :rules="[{ required: true, message: $t('claim.travelRulesPassportNo') }]"
        required
        :name="'identityNo'"
        autosize
        :label="$t('claim.PassportNoLabel')"
      />
    </Form>

    <Popup
      v-model="visibleForPopup"
      position="bottom"
      round
      get-container="body"
    >
      <DatetimePicker
        v-model="currentDate"
        :type="'date'"
        v-bind="dateProps"
        :confirm-button-text="$t('confirm')"
        :cancel-button-text="$t('cancel')"
        :columns-order="['day', 'month', 'year']"
        @confirm="onConfirm"
        @cancel="visibleForPopup = false"
      />
    </Popup>
  </div>

</template>

<script>
import { Form, Field, Popup, DatetimePicker } from 'vant'
import Mixin from '@/views/claim/report/mixin.js'
import Dao from '@/utils/dao'
import { Session } from '@/utils/storage'

export default {
  components: { Form, Field, Popup, DatetimePicker },
  mixins: [Mixin],
  props: {},
  data() {
    return {
      visibleForPopup: false,
      currentDate: new Date(),
      dateProps: {
        type: 'date',
        title: 'Birthday',
        maxDate: new Date(),
        minDate: new Date(1930, 0, 1)
      },
      value: '',
      form: {
        lastName: '',
        identityNo: '',
        firstName: '',
        birthday: '',
        dateText: ''
      }
    }
  },
  computed: {
    isEmpty() {
      return false
    }
  },
  inject: ['reshow'],
  mounted() {
    if (this.reshow()) {
      const res = Session.get('reportby')
      this.form = {
        ...res
      }
    }
  },
  methods: {
    getTargetDate(targetDate) {
      // IDP-6732 - Date Picker in Quotation Form should use service time
      const serverDate = Session.get('serverDate')
      // 获取最新服务器时间
      const lastHttpDate = serverDate || Dao.get('LastHttpDate')
      const currentDate = targetDate ? new Date(targetDate) : lastHttpDate ? new Date(lastHttpDate) : new Date()

      return {
        y: currentDate.getFullYear(),
        m: currentDate.getMonth(),
        d: currentDate.getDate(),
        H: currentDate.getHours(),
        M: currentDate.getMinutes(),
        S: currentDate.getSeconds()
      }
    },
    // 正常选择时间点击确认
    onConfirm(value) {
      const { y, m, d } = this.getTargetDate(value)

      // 提交字段的模式
      // 默认是时间戳
      this.form.dateText = `${d}/${m + 1}/${y}`
      this.form.birthday = value.getTime()
      console.log(this.form.dateText)
      this.visibleForPopup = false
    }
  }
}
</script>
<style lang="scss">
@import '@/components/FuFormMin/styles/index.scss';
@import '@/components/FuFormMin/styles/theme.scss';
</style>
<style lang="scss" scoped>
.fu-form-mini {
  ::v-deep .van-cell {
    padding: 0;
    overflow: visible;
    display: unset;
  }
  ::v-deep .van-field__label {
    width: unset;
  }
  ::v-deep .van-field__value {
    margin: 0px 2px 2px;
  }
  .form-item-input {
    ::v-deep .van-field__body {
      flex-direction: row-reverse;
    }
  }
}
::v-deep  .van-picker-column__item .van-notice-bar__wrap{
  justify-content: center;
}

.fu-min-select-box {
  // min-height: 30vh;
  min-height: 20vh;
  max-height: 60vh;
}

.select-tit {
  font-size: 12px;
  font-weight: 900;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.fu-select-list-cell.active {
  color: #ee0a24;
  font-weight: 500;
}
</style>
