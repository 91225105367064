<template>
  <div class="footer fu-fixed">
    <Button
      v-if="$parent.$parent.step > 0"
      type="danger"
      plain
      color="#d9000b"
      @click="$parent.$parent.step = $parent.$parent.step - 1"
    >
      {{ $t('claim.back') }}
    </Button>

    <Button
      type="danger"
      color="#d9000b"
      v-bind="$attrs"
      @click="handleNext"
    >
      {{ $t('claim.next') }}
    </Button>
  </div>

</template>

<script>
import { Button } from 'vant'
export default {
  components: { Button },
  props: {},
  data() {
    return {
    }
  },
  methods: {
    handleNext() {
      this.$emit('next')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 40px;
  background: #fff;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 1;
  .van-button{
    // background: #C82A21;
    box-shadow: 0px 0.714988px 2.85995px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    height: 47px;
    line-height: 47px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    .van-button__text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      // color: #FFFFFF;
    }
  }
}
</style>
