<template>
  <div class="report-by">
    <p class="report-by-title">{{ $t('claim.Reportby') }}:</p>
    <RadioGroup v-model="radio" direction="horizontal">
      <Radio name="1" checked-color="#ee0a24">{{ $t('claim.InsuredObject') }}</Radio>
      <Radio name="2" checked-color="#ee0a24">{{ $t('claim.PolicyNoLabel') }}</Radio>
    </RadioGroup>

    <component :is="componetName" ref="componentName" />
    <DateOfLoss v-if="radio" ref="dateOfLoss" />

    <Footer :disabled="!radio" @next="handleNext" />
  </div>

</template>

<script>
import { RadioGroup, Radio } from 'vant'
import Footer from '../footer/index.vue'
import Car from './components/car/index.vue'
import Moto from './components/moto/index.vue'
import Property from './components/property/index.vue'
import Travel from './components/travel/index.vue'
import PolicyNo from './components/policyNo/index.vue'
import DateOfLoss from './components/dateOfLoss/index.vue'
import { Session } from '@/utils/storage'

export default {
  components: { Footer, RadioGroup, Radio, Car, Moto, Property, Travel, PolicyNo, DateOfLoss },
  props: {
    value: { type: Object, default() { return {} } }
  },
  data() {
    return {
      radio: ''
    }
  },
  computed: {
    componetName() {
      function capitalizeFirstLetter(str) {
        if (typeof str !== 'string' || str.length === 0) {
          return ''
        }
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
      if (this.radio == 2) {
        return 'PolicyNo'
      } else if (this.radio == 1) {
        console.log(this.value.productCategory)
        return capitalizeFirstLetter(this.value.productCategory)
      }
      return ''
    }
  },
  inject: ['reshow'],
  methods: {
    async handleNext() {
      // this.$refs.componentName.$refs.formRef.validate().then(res => {
      //   this.value.reportBy = this.$refs.componentName.form
      //   this.$parent.step = 2
      // }).catch(e => {
      // })
      let dateOfLoss
      try {
        await this.$refs.componentName.$refs.formRef.validate()
        if (this.$refs.dateOfLoss) {
          await this.$refs.dateOfLoss.$refs.formRef.validate()
          dateOfLoss = this.$refs.dateOfLoss.form.dateOfLoss
        }
        let reportBy = {}
        if (this.radio == 2) {
          reportBy = { policyNo: this.$refs.componentName.form.policyNo }
        } else {
          reportBy = JSON.parse(JSON.stringify(this.$refs.componentName.form))
          delete reportBy.policyNo
        }
        Session.set('reportby', reportBy)
        Session.set('dateofloss', this.$refs.dateOfLoss.form)
        this.value.reportBy = { ...reportBy, dateOfLoss }
        this.$parent.step = 2
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.report-by {
  font-size: 14px;
}
.report-by-title {
  font-weight: bold;
  font-size: 18px;
  margin: 20px 0;
}
</style>
