
import { Session } from '@/utils/storage'
import { getTenantRegion } from '@fu/core'

export default {
  data() {
    const appInfo = Session.get('appInfo')
    return {
      appInfo
    }
  },
  computed: {
    lang() {
      return this.appInfo.lan || 'en'
    },
    isIDEnv() {
      return getTenantRegion() === 'ID'
    },
    isTHEnv() {
      return getTenantRegion() === 'TH'
    },
    isVNEnv() {
      return getTenantRegion() === 'VN'
    },
    getPrefixObj() {
      try {
        const prefixConfig = {
          'ID': '62',
          'TH': '66',
          'VN': '84'
        }
        return prefixConfig[getTenantRegion()]
      } catch (e) {
        return ''
      }
    }
  },
  methods: {
  }
}
