<template>
  <div>
    <Form ref="info" class="fu-form-mini-theme fu-form-mini">
      <field
        v-model="form.reporter"
        :rules="[{ required: true, message: $t('claim.reporterNameRules') }]"
        required
        :name="'reporter'"
        autosize
        :label="$t('claim.ReporterNameLabel')"
      />

      <field
        v-model="form.reporterMobile"
        class="form-item-input"
        :rules="[{ required: true, message: $t('claim.reporterMobileRules') }]"
        required
        :name="'reporterMobile'"
        autosize
        :label="$t('claim.ReporterMobileLabel')"
      >
        <template #button>
          {{ getPrefixObj }}
        </template>
      </field>

      <field
        v-model="form.reporterEmail"
        :name="'reporterEmail'"
        autosize
        :label="$t('claim.ReporterEmailLabel')"
      />
    </Form>

    <Footer :disabled="loading" @next="handleNext" />

    <div v-if="showSubmitSuccess" class="overlay">
      <img :src="img" class="success-image">
      <div class="success-title">{{ $t('claim.Thankyou') }}</div>
      <div class="success-message">{{ $t('claim.ClaimSaveSubmitSuccess') }}</div>
    </div>
  </div>

</template>

<script>
import { Form, Field, Dialog, Toast } from 'vant'
import { claimCustomerSubmit, claimPolicySubmit } from '@/views/claim/api/index.js'
import Footer from '../footer/index.vue'
// import { Session } from '@/utils/storage'
import Mixin from '@/views/claim/report/mixin.js'

export default {
  components: { Form, Field, Footer },
  mixins: [Mixin],
  props: {
    value: { type: Object, default: () => {} }
  },
  data() {
    return {
      loading: false,
      form: {
        showSubmitSuccess: false,
        reporter: '',
        reporterMobile: '',
        reporterEmail: ''
      }
    }
  },
  methods: {
    async partnerSubmitEvent() {
      const { productCategory, reportBy, slipUidList, reportInfo } = this.value
      const params = {
        productCategory,
        ...reportBy,
        slipUidList,
        ...reportInfo
      }
      if (params.reporterMobile) {
        params.reporterMobile = `${this.getPrefixObj}${params.reporterMobile}`
      }
      this.loading = true
      Toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const res = await claimCustomerSubmit(params)
      Toast.clear()
      this.loading = false
      if (!res.id) {
        if (res.reportUid) {
          this.reportUid = res.reportUid
          this.showSubmitSuccess = true
        }
        return
      }
      // console.log(res)
      Dialog.confirm({
        title: this.$t('claim.ReportSubmitSuccessTitle'),
        message: this.$t('claim.ReportSubmitSuccessMessage'),
        confirmButtonText: 'continue',
        cancelButtonText: 'close'
      }).then(() => {
        // on confirm
        // Session.set('claimApplication', res)
        window.location.href = res.formUrl
      }).catch(() => {
        window.close()
      })
    },
    async policySubmitEvent() {
      const { reportInfo } = this.value
      const params = {
        slipUid: this.$route.query.slipUid,
        ...reportInfo
      }
      if (params.reporterMobile) {
        params.reporterMobile = `${this.getPrefixObj}${params.reporterMobile}`
      }
      this.loading = true
      Toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const res = await claimPolicySubmit(params)
      Toast.clear()
      this.loading = false
      if (!res.id) {
        if (res.reportUid) {
          this.reportUid = res.reportUid
          this.showSubmitSuccess = true
        }
        return
      }
      // console.log(res)
      Dialog.confirm({
        title: this.$t('claim.ReportSubmitSuccessTitle'),
        message: this.$t('claim.ReportSubmitSuccessMessage'),
        confirmButtonText: 'continue',
        cancelButtonText: 'close'
      }).then(() => {
        // on confirm
        // Session.set('claimApplication', res)
        if (res.formUrl) {
          window.location.href = res.formUrl
        }
      }).catch(() => {
        window.close()
      })
    },
    async handleNext() {
      const isPolicy = this.$route.query.slipUid
      if (!await this.$refs.info.validate()) {
        this.value.reportInfo = this.form
        isPolicy ? this.policySubmitEvent() : this.partnerSubmitEvent()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/components/FuFormMin/styles/index.scss';
@import '@/components/FuFormMin/styles/theme.scss';

.fu-form-mini {
  ::v-deep .van-cell {
    padding: 0;
    overflow: visible;
    display: unset;
  }
  ::v-deep .van-field__label {
    width: unset;
  }
  ::v-deep .van-field__value {
    margin: 0px 2px 2px;
  }
  ::v-deep .van-field__body {
    flex-direction: row-reverse;
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  z-index: 999;
  .success-image {
    border: none;
    width: 150px;
    max-width: 100%;
    vertical-align: middle;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .success-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    color: #49e197;
    top: 50%;
    width: 100%;
  }
  .success-message {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    color: #000;
    bottom: 10%;
    width: 100%;
  }
}
</style>
