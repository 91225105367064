<template>
  <div class="">
    <Form ref="formRef" class="fu-form-mini-theme fu-form-mini">
      <field
        v-if="isIDEnv"
        :rules="[{ required: true, message: $t('claim.motoRulesPlateCode') }]"
        required
        readonly
        clickable
        :value="form.plateCode"
        :name="'plateCode'"
        right-icon="arrow-down"
        autosize
        :label="$t('claim.PlateCodeLabel')"
        @click="visibleForPopup = true"
      />

      <field
        v-model="form.plateNo"
        class="form-item-input"
        :rules="[{ required: true, message: $t('claim.motoRulesPlateNo') }]"
        required
        :name="'plateNo'"
        autosize
        :label="$t('claim.PlateNumberLabel')"
      >
        <template v-if="form.plateCode" #button>
          {{ form.plateCode }}
        </template>
      </field>

      <field
        v-model="form.vehicleVinNo"
        :rules="[{ required: true, message: $t('claim.motoRulesChassisNo') }]"
        required
        :name="'vehicleVinNo'"
        autosize
        :label="$t('claim.ChassisNumberLabel')"
      />

      <field
        v-model="form.vehicleEngineNo"
        :rules="[{ required: true, message: $t('claim.motoRulesEngineNo') }]"
        required
        :name="'vehicleEngineNo'"
        autosize
        :label="$t('claim.EngineNumberLabel')"
      />
    </Form>

    <Popup
      v-model="visibleForPopup"
      position="bottom"
      round
      get-container="body"
    >
      <div v-if="loading" class="fu-form-min-address-overlay">
        <Loading />
      </div>
      <Empty v-if="isEmpty" />

      <Picker
        v-else
        v-model="value"
        :title="$t('claim.PlateCodeLabel')"
        show-toolbar
        :value-key="'value'"
        :loading="loading"
        :columns="options"
        item-height="50"

        :confirm-button-text="$t('confirm')"
        :cancel-button-text="$t('cancel')"
        @cancel="visibleForPopup = false"
        @confirm="onConfirm"
      >
        <!-- 正常文案展示 -->
        <template
          slot="option"
          slot-scope="scope"
        >
          <!-- 自定义内容是为以防内容过长的时候无法查看全部内容 -->
          <NoticeBar
            wrapable
            :scrollable="false"
            style="width: 100%"
            color="#000"
            background="transparent"
            :text="lang == 'en' ? scope.value : scope.valueLocal"
          />
        </template>
      </Picker>
    </Popup>
  </div>

</template>

<script>
import { Form, Field, Loading, Empty, Popup, NoticeBar, Picker } from 'vant'
import { claimCodetable } from '@/views/claim/api/index.js'
import Mixin from '@/views/claim/report/mixin.js'
import { Session } from '@/utils/storage'

export default {
  components: { Form, Field, Loading, Empty, Popup, NoticeBar, Picker },
  mixins: [Mixin],
  props: {},
  data() {
    return {
      visibleForPopup: false,
      loading: false,
      options: [],
      value: '',
      form: {
        vehicleVinNo: '', // 车架号
        vehicleEngineNo: '', // 引擎号
        plateNo: '', // 车牌号
        plateCode: '' // 车牌区域
      }
    }
  },
  computed: {
    isEmpty() {
      return false
    }
  },
  inject: ['reshow'],
  mounted() {
    if (this.reshow()) {
      const res = Session.get('reportby')
      this.form = {
        ...res
      }
    }
    this.claimCodetable()
  },
  methods: {
    async claimCodetable() {
      const res = await claimCodetable({ codeTable: 'id_area' })
      this.options = res
    },
    onConfirm(e) {
      this.form.plateCode = e.code
      this.visibleForPopup = false
    }
  }
}
</script>
<style lang="scss">
@import '@/components/FuFormMin/styles/index.scss';
@import '@/components/FuFormMin/styles/theme.scss';
</style>
<style lang="scss" scoped>
.fu-form-mini {
  ::v-deep .van-cell {
    padding: 0;
    overflow: visible;
    display: unset;
  }
  ::v-deep .van-field__label {
    width: unset;
  }
  ::v-deep .van-field__value {
    margin: 0px 2px 2px;
  }
  .form-item-input {
    ::v-deep .van-field__body {
      flex-direction: row-reverse;
    }
  }
}
::v-deep  .van-picker-column__item .van-notice-bar__wrap{
  justify-content: center;
}

.fu-min-select-box {
  // min-height: 30vh;
  min-height: 20vh;
  max-height: 60vh;
}

.select-tit {
  font-size: 12px;
  font-weight: 900;
  padding-left: 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.fu-select-list-cell.active {
  color: #ee0a24;
  font-weight: 500;
}
</style>
