<template>
  <div>
    <Empty v-if="!options.length" description="No Policy" />
    <CheckboxGroup v-model="result" class="policy-item">
      <Checkbox
        v-for="(item, index) in options"
        :key="`check${index}`"
        :name="item.slipId"
      >
        <template #default>
          <div>
            <p class="policy-no"><span class="policy-no-label">{{ $t('claim.PolicyNoLabel') }}:</span> {{ item.policyNo }}</p>
            <div class="insurance-info-box">
              <!-- <p class="insurance-info-name">{{ item.insuranceName }}</p> -->
              <p class="insurance-info-name">{{ item.productName }}</p>
              <img :src="item.insuranceLogo" class="insurance-info-logo">
            </div>
            <!-- <p class="product-name">{{ item.productName }}</p> -->
            <p class="product-date">{{ formatDateTime(item.startTime,'', '', 'ss') }} - {{ formatDateTime(item.endTime,'', '', 'ss') }}</p>
            <Collapse
              :value="computedCollapse(index)"
              accordion
            >
              <CollapseItem
                title="insurance Information"
                name="1"
                @click.native.stop="toggleCollapse(index, '1')"
              >
                <div class="collapse-item-flex"><p>{{ $t('claim.Period') }}</p><p>{{ item.coveragePeriod }}</p></div>
              </CollapseItem>
              <CollapseItem
                title="insured Information"
                name="2"
                @click.native.stop="toggleCollapse(index, '2')"
              >
                <div class="collapse-item-flex"><p>{{ $t('claim.InsuredNameLabel') }}</p><p>{{ item.insuredName }}</p></div>
                <div
                  v-for="(i, k) in item.insuredArray"
                  :key="`insured${index}-${k}`"
                  class="collapse-item-flex"
                ><p>{{ i.label }}</p><p>{{ i.value }}</p></div>
              </CollapseItem>
            </Collapse>
          </div>
        </template>
      </Checkbox>
    </CheckboxGroup>

    <Footer :disabled="!result.length" @next="handleNext" />
  </div>

</template>

<script>
import { Checkbox, CheckboxGroup, Collapse, CollapseItem, Notify, Empty, Toast } from 'vant'
import Footer from '../footer/index.vue'
import { claimQueryReportPolicyList, claimReportPolicyQueryByCode } from '@/views/claim/api/index.js'
import { formatDateTime } from '@fu/core'
export default {
  components: { Checkbox, CheckboxGroup, Collapse, CollapseItem, Footer, Empty },
  props: {
    value: { type: Object, default: () => {} }
  },
  data() {
    return {
      loading: true,
      result: [],
      options: [],
      activeNames: [],
      formatDateTime
    }
  },
  computed: {
    computedCollapse() {
      return (index) => {
        if (!this.activeNames[index]) {
          this.$set(this.activeNames, index, 3)
        }
        return this.activeNames[index]
      }
    }
  },
  mounted() {
    this.claimQueryReportPolicyList()
  },
  methods: {
    async claimQueryReportPolicyList() {
      const { productCategory, reportBy } = this.value
      const params = {
        productCategory, ...reportBy
      }
      this.loading = true
      Toast.loading({ forbidClick: true, loadingType: 'spinner' })
      const func = reportBy.policyNo ? claimReportPolicyQueryByCode : claimQueryReportPolicyList
      const res = await func(params)
      Toast.clear()
      this.loading = true
      this.options = res.map(item => {
        if (!item.insuredObjectFieldJson || !item.insuredObjectValueJson) return item
        const insuredObjectField = JSON.parse(item.insuredObjectFieldJson)
        const insuredObjectValue = JSON.parse(item.insuredObjectValueJson)
        item.insuredArray = Object.keys(insuredObjectField).map(key => {
          return {
            label: insuredObjectField[key],
            value: insuredObjectValue[key]
          }
        })
        return item
      })
    },
    toggleCollapse(index, e) {
      if (!e) {
        this.$set(this.activeNames, index, '3')
      } else if (this.activeNames[index] == e) {
        this.$set(this.activeNames, index, '3')
      } else {
        this.$set(this.activeNames, index, e)
      }
    },
    handleNext() {
      if (!this.result?.length) {
        Notify({ type: 'warning', message: 'Choose at least one Policy' })
        return
      }
      this.value.slipUidList = this.result
      this.$parent.step = 3
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-checkbox {
  align-items: flex-start;
  padding: 10px 10px 0 10px;
  margin: 10px 0;
  box-shadow: 0px -2.5px 10px rgba(0, 0, 0, 0.1);
}
::v-deep .van-checkbox__label {
  flex: 1 1;
}
::v-deep .van-checkbox__icon--checked .van-icon {
  background-color: #EE0A24;
  border-color: #EE0A24;
}
::v-deep .van-collapse-item .van-cell {
  padding: 10px 0px;
}
.product-name {
  font-size: 16px;
  font-weight: bold;
  line-height: 0.48rem;
  font-style: italic;
  text-decoration: underline;
}
.product-date {
  margin: 10px 0;
  font-size: 12px;
  line-height: 12px;
}
.policy-item {
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px -2.5px 0.26667rem rgba(0, 0, 0, 0.1);
}
.collapse-item-flex {
  display: flex;
  justify-content: space-between;
}
.insurance-info-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 15px 0;
  .insurance-info-name {
    font-size: 16px;
    font-weight: bold;
  }
  .insurance-info-logo {
    width: 30px;
    height: 30px;
  }
}
.policy-no {
  font-size: 12px;
  margin: 0 0 15px 0;
  color: #EE0A24;
  .policy-no-label {
    color: #EE0A24;
  }
}
</style>
